<template>
  <div>
    <order-details-top :transaction="transaction"
                       :payway="payway"
                       :visibility="visibilityOptions"
                       :show-button="true"
                       v-if="canShowMobileOrderDetails" />
    <main>
      <payment-loading v-if="isLoading"/>
      <div v-else>
        <error-data v-if="isErrorDataSet"/>
        <div v-else>
          <payment-processing v-if="isProcessing"/>
          <div v-else>
            <regulations-approval-form />
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import OrderDetailsTop from '@/base/components/OrderDetails/Top'
import PaymentLoading from '@/base/components/Payment/Loader/Loading'
import ErrorData from '@/base/components/Error/Data'
import PaymentProcessing from '@/base/components/Payment/Processing/Processing'
import RegulationsApprovalForm from '@/base/components/RegulationsApproval/Form'
import viewMixin from '@/base/mixins/view.mixin'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import { pageTitle, ROUTING } from '@/base/const/routing.const'

export default {
  name: 'RegulationsApproval',
  metaInfo () {
    return {
      title: pageTitle().base,
      titleTemplate: '%s | ' + pageTitle().regulationsApproval
    }
  },
  mixins: [viewMixin],
  components: {
    RegulationsApprovalForm,
    PaymentProcessing,
    ErrorData,
    PaymentLoading,
    OrderDetailsTop
  },
  props: {
    apiToken: {
      type: String,
      required: false
    },
    idTransRemote: {
      type: String,
      required: false
    },
    continueHash: {
      type: String,
      required: false
    }
  },
  computed: {
    ...mapState({
      isLoading: state => state.payment.loading,
      isProcessing: state => state.payment.processing,
      paymentData: state => state.payment.paymentData,
      transaction: state => state.payment.paymentData?.transaction || null,
      payway: state => {
        if (!state.payment.paymentData || !state.payment.paymentData.payways) {
          return null
        }

        if (state.payment.paymentData.chosenPaywayId) {
          return state.payment.paymentData.payways.find(payway => payway.id === state.payment.paymentData.chosenPaywayId)
        }
        if (state.payment.paymentData.chosenPayway) {
          return state.payment.paymentData.chosenPayway
        }
      },
      visibilityOptions: state => state.payment.paymentData ? state.payment.paymentData.visibilityOptions : null
    }),
    ...mapGetters({
      isErrorDataSet: 'isErrorDataSet'
    }),
    canShowMobileOrderDetails: function () {
      return !this.isLoading
    }
  },
  methods: {
    ...mapMutations([
      'SET_ERROR_DATA'
    ]),
    ...mapActions([
      'getPaymentRegulationsApprovalContinueTrx',
      'getPaymentRegulationsApprovalStart',
      'setPaymentActivePayway'
    ]),
    getDataFromStart () {
      const data = {
        apiToken: this.apiToken
      }
      this.getPaymentRegulationsApprovalStart(data)
        .catch(error => {
          if (error.message === 'psd2Data') {
            return
          }
          this.SET_ERROR_DATA({
            errorCode: error.body?.errorCode,
            errorUrl: error.body?.errorUrl
          })
        })
    },
    getDataFromContinue () {
      const data = {
        idTransRemote: this.idTransRemote,
        continueHash: this.continueHash
      }
      this.getPaymentRegulationsApprovalContinueTrx(data)
        .catch(error => {
          if (error.message === 'psd2Data') {
            return
          }
          this.SET_ERROR_DATA({
            errorCode: error.body?.errorCode,
            errorUrl: error.body?.errorUrl
          })
        })
    }
  },
  mounted () {
    if (!this.paymentData) {
      if (this.apiToken) {
        this.getDataFromStart()
        return
      }
      if (this.idTransRemote && this.continueHash) {
        this.getDataFromContinue()
        return
      }
      this.$router.push(ROUTING.paywayError)
    } else {
      this.setPaymentActivePayway(this.payway)
    }
  }
}
</script>
